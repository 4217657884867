import { SharedAngular } from '@Client/@types/sharedAngular';
import { useService } from '@Client/runner.hooks/useService';
import { Box, Button, Dialog, Slider, Stack } from '@mui/material';
import { Diagram } from 'FlowGoJS';
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import ShareModal from '../ShareModal/ShareModal';
import { RunnerPublicMapApiServiceType } from '@Client/runner.services/runner.publicmap.api.service';
import IProcessMap, { Step } from '@Shared.Angular/@types/processMap';
import IProcessMapNodeViewModel from '@Shared.Angular/@types/processMapNodeViewModel';
import { NodeCategory } from '@Shared.Angular/flowingly.services/flowingly.constants';
import { ProcessMapNodeCateories } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/processMapNodeCateories';

type Props = {
  isVisible: boolean;
  processMapId: string;
  processMapHeight?: number;
  onProcessMapRetrieved?: (processMap: IProcessMap) => void;
  onProcessMapLoad: (map: IProcessMap) => void;
  onSelectedNodeDataChange: (selectedNode: unknown) => void;
  hideFlowModelOpenButton: boolean;
};
type ProcessMapSchemaMapping = Record<string, IProcessMap>;
type ProcessMapComponentSchemaMapping = Record<string, IProcessMap>;
const ProcessMapSchemaAndIdMapping: ProcessMapSchemaMapping = {};
const ProcessMapComponentSchemaAndSchemaIdMapping: ProcessMapComponentSchemaMapping =
  {};

export const ProcessMap = (props: Props) => {
  const [diagramLoaded, setDiagramLoaded] = useState(false);
  const [diagram, setDiagram] = useState<Diagram>(null);
  const [processMap, setProcessMap] = useState<IProcessMap>(null);
  const [zoomValue, setZoomValue] = useState(100);
  const [currentProcessMapId, setCurrentProcessMapId] = useState('');
  const [initialScale, setInitialScale] = useState<number | null>(null);
  const [flowSchema, setFlowSchema] = useState(null);
  const [isFlowModelEditable, setIsFlowModelEditable] =
    useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const permissionsService =
    useService<SharedAngular.PermissionsService>('permissionsService');
  const tempModelerUrlService = useService<SharedAngular.TempModelerUrlService>(
    'tempModelerUrlService'
  );
  const flowinglyConstants =
    useService<SharedAngular.FlowinglyConstants>('flowinglyConstants');
  const workflowApiService =
    useService<SharedAngular.WorkflowApiService>('workflowApiService');
  const pubSubService =
    useService<SharedAngular.PubSubService>('pubsubService');
  const flowinglyDiagramService =
    useService<SharedAngular.FlowinglyDiagramService>(
      'flowinglyDiagramService'
    );
  const appInsightsService =
    useService<SharedAngular.AppInsightsService>('appInsightsService');
  const goService = useService<GoJS>('goService');

  const runnerPublicMapApiServiceType =
    useService<RunnerPublicMapApiServiceType>('runnerPublicMapApiService');
  const divRef = useRef<HTMLDivElement>(null);

  const shareModalClose = () => setShareModalOpen(false);
  const onShareModelHandler = async (event) => {
    setShareModalOpen(true);
  };

  const {
    isVisible,
    processMapId,
    processMapHeight = window.innerHeight - 275,
    onProcessMapLoad,
    onProcessMapRetrieved,
    onSelectedNodeDataChange,
    hideFlowModelOpenButton
  } = props;

  /**
   * load process map when process map Id changes
   */
  useEffect(() => {
    if (currentProcessMapId !== processMapId) {
      const cachedProcessMap = ProcessMapSchemaAndIdMapping[processMapId];
      if (cachedProcessMap) {
        handleMapLoading(cachedProcessMap as IProcessMap);
      } else {
        loadProcessMapByFlowModelId(processMapId);
      }
    }
  }, [processMapId]);

  /**
   * render diagram when process map loaded
   */
  useEffect(() => {
    if (processMap && processMapId === processMap.flowId) {
      updateViewModelEdit();
      const dia = renderDiagram(processMap);
      setZoomValue(100);
      setDiagram(dia);
      setDiagramLoaded(true);
    }
  }, [processMap, processMapHeight]);

  /**
   * addDiagramListener & removeDiagramListener when diagram changed
   */
  useEffect(() => {
    if (diagram) {
      diagram.addDiagramListener('ChangedSelection', onChangedSelection);
      diagram.addDiagramListener('AnimationFinished', onAnimationFinished);

      return () => {
        diagram.removeDiagramListener('ChangedSelection', onChangedSelection);
        diagram.removeDiagramListener('AnimationFinished', onAnimationFinished);
      };
    }
  }, [diagram]);

  useEffect(() => {
    if (!isVisible) {
      diagram?.clearSelection();
    }
  }, [isVisible]);

  useEffect(() => {
    if (!diagram) {
      return;
    }

    diagram.scale =
      initialScale *
      Math.pow(diagram.commandHandler.zoomFactor, zoomValue - 100);
  }, [zoomValue]);

  useEffect(() => {
    const elem = divRef.current;
    const option = { passive: false };

    elem.addEventListener('wheel', handleMouseScroll, option);
    return () => {
      elem.removeEventListener('wheel', handleMouseScroll);
    };
  }, []);

  const DEADLINETYPES = {
    none: 'None'
  };

  const updateViewModelEdit = () => {
    const hasPermission = permissionsService.currentUserHasPermission(
      flowinglyConstants.permissions.FLOWMODEL_UPDATE
    );

    setIsFlowModelEditable(hasPermission || processMap.editableByCurrentUser);
  };

  const renderDiagram = (map: IProcessMap) => {
    const dia: Diagram = flowinglyDiagramService.generateProcessModel(
      {
        flow: map,
        applyAvatar: false,
        modelCustomArgs: {
          scrollMode: goService.Diagram.DocumentScroll
        },
        allowSelect: true,
        dynamicInitialHeight: processMapHeight,
        allowMove: true
      },
      doKeyDownHandler
    );

    if (dia) {
      dia.commandHandler.canIncreaseZoom = () => false;
      dia.commandHandler.canDecreaseZoom = () => false;
      dia.commandHandler.canResetZoom = () => false;
    }
    return dia;
  };

  const doKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    setZoomValue((prevZoomValue) => {
      let newValue = prevZoomValue;
      switch (event.key.toUpperCase()) {
        case 'ADD':
          newValue = Math.min(prevZoomValue + 1, 200);
          break;
        case 'SUBTRACT':
          newValue = Math.max(prevZoomValue - 1, 0);
          break;
        case '0':
          newValue = 100;
          break;
        default:
          break;
      }
      return newValue;
    });
  };

  const handleMapLoading = async (map: IProcessMap) => {
    if (onProcessMapRetrieved) {
      onProcessMapRetrieved(map);
    }

    // the event should be published on the initial parent processMap rendering and not on subsequent component renderings.
    if (!currentProcessMapId) {
      pubSubService.publish('PROCESSMAPVIEWV2_LOADED', { name: map.name });
    }

    setCurrentProcessMapId(map.flowId);

    map.Id = map.flowId;
    map.isProcessMap = true;

    const schema = JSON.parse(map.flowSchema ?? '[]');

    setProcessMap(map);
    setFlowSchema(schema);
    onProcessMapLoad(map);

    appInsightsService.trackMetricIfTimerExist('flowRenderProcessMap', {
      flowIdentifier: map.name
    });
  };

  const loadProcessMapByFlowModelId = async (processMapId: string) => {
    appInsightsService.startEventTimer('flowRenderProcessMap');
    try {
      const map = hideFlowModelOpenButton
        ? await runnerPublicMapApiServiceType.getPublicProcessMap(processMapId)
        : await workflowApiService.getProcessMap(processMapId, true);

      await handleMapLoading(map as IProcessMap);
      cacheProcessMap(map);
      return map;
    } catch (error) {
      await handleMapLoading(null);
    }
  };

  const loadProcessMapBySchemaId = async (schemaId: string) => {
    if (!schemaId) {
      return;
    }
    appInsightsService.startEventTimer('flowRenderProcessMap');
    const cachedProcessMapComponent =
      ProcessMapComponentSchemaAndSchemaIdMapping[schemaId];
    if (cachedProcessMapComponent) {
      await handleMapLoading(cachedProcessMapComponent);
      return cachedProcessMapComponent;
    }

    const map = await workflowApiService.getProcessMapbySchemaId(
      schemaId,
      true,
      hideFlowModelOpenButton
    );

    await handleMapLoading(map as IProcessMap);
    cacheProcessMapComponent(schemaId, map);
    return map;
  };

  const onChangedSelection = async () => {
    const selectedNode = diagram.selection.first();

    const selectedNodeData =
      selectedNode instanceof goService.Node ? selectedNode.data : null;

    if (
      selectedNodeData === null ||
      selectedNode.category === NodeCategory.POOL ||
      selectedNode.category === NodeCategory.LANE ||
      selectedNode.category === NodeCategory.EVENT ||
      selectedNode.category === NodeCategory.DIVERGE_GATEWAY ||
      selectedNode.category === NodeCategory.CONVERGE_GATEWAY
    ) {
      onSelectedNodeDataChange(null);
      return;
    }

    let nodeElement: any = {};

    // If the selected node is an exclusive gateway, fetch from exclusiveGateways
    if (selectedNodeData.category === NodeCategory.EXCLUSIVE_GATEWAY) {
      nodeElement.decisionGateway = processMap.exclusiveGateways.find(
        (n) => n.id === selectedNodeData.id
      );
      nodeElement.category = ProcessMapNodeCateories.DecisionGateway;
      onSelectedNodeDataChange(nodeElement);
      return;
    }

    if (selectedNode.category === NodeCategory.COMPONENT) {
      // To close the right panel while switching between components
      onSelectedNodeDataChange(null);
      // To reset the zoom slider
      handleSliderChange(null, 100);
      await loadProcessMapBySchemaId(selectedNodeData.componentSchemaId);
    }

    nodeElement = processMap.steps.find(
      (n) => n.id === selectedNodeData.id
    ) as IProcessMapNodeViewModel | null;
    const { fields } = nodeElement || {};

    if (!fields || fields.length === 0) {
      const stepFields = await workflowApiService.getProcessMapNodeElement(
        processMap.flowId,
        selectedNodeData.id,
        false,
        hideFlowModelOpenButton
      );
      if (nodeElement) {
        nodeElement.fields = stepFields;
      }
    }
    updateSeletedStepValues(nodeElement);
    onSelectedNodeDataChange(nodeElement);
  };

  const onAnimationFinished = () => {
    setInitialScale(diagram.scale);
    diagram.links.each(function (link) {
      link.invalidateRoute();
    });
  };

  const updateSeletedStepValues = (selectedStep: Step) => {
    selectedStep.deadLine = formatDisplay(
      selectedStep.deadLineNumber,
      selectedStep.deadLineType
    );
    selectedStep.plannedTime = formatDisplay(
      selectedStep.costTimeNumber,
      selectedStep.costTimeType
    );
    selectedStep.waitingTime = formatDisplay(
      selectedStep.waitTime,
      selectedStep.waitTimeType
    );
    selectedStep.stepActor = flowSchema.nodeDataArray.find(
      (n) => n.id == selectedStep.id
    ).actorName;
    selectedStep.reminder = formatDisplay(
      selectedStep.reminderNumber,
      selectedStep.reminderType
    );

    selectedStep.fields?.forEach((f) => {
      f.isNodeFromRunnerProcessMap = true;
      f.type = convertNumericToEnumFieldType(f.type);
    });
  };

  const formatDisplay = (num: number, type: string) => {
    let deadline = null;

    if (type == DEADLINETYPES.none) {
      return DEADLINETYPES.none;
    }

    if (num && type) {
      if (num == 1 && type != DEADLINETYPES.none) {
        type = type.slice(0, type.length - 1);
      }
      deadline = num + ' ' + type;
    }
    return deadline;
  };

  const convertNumericToEnumFieldType = (numfield: number) => {
    switch (numfield) {
      case 0:
        return flowinglyConstants.formFieldType.APPROVAL_COMMENT;
      case 1:
        return flowinglyConstants.formFieldType.APPROVAL_RULE;
      case 3:
        return flowinglyConstants.formFieldType.CHECKBOX;
      case 4:
        return flowinglyConstants.formFieldType.CURRENCY;
      case 5:
        return flowinglyConstants.formFieldType.EMAIL;
      case 6:
        return flowinglyConstants.formFieldType.FILE_UPLOAD;
      case 7:
        return flowinglyConstants.formFieldType.INSTRUCTION;
      case 8:
        return flowinglyConstants.formFieldType.TABLE;
      case 9:
        return flowinglyConstants.formFieldType.TEXT;
      case 10:
        return flowinglyConstants.formFieldType.TEXTAREA;
      case 12:
        return flowinglyConstants.formFieldType.NUMBER;
      case 13:
        return flowinglyConstants.formFieldType.PASSWORD;
      case 14:
        return flowinglyConstants.formFieldType.RADIO_BUTTON_LIST;
      case 15:
        return flowinglyConstants.formFieldType.SELECT_LIST;
      case 17:
        return flowinglyConstants.formFieldType.TASK_LIST;
      case 18:
        return flowinglyConstants.formFieldType.MULTISELECT_LIST;
      case 19:
        return flowinglyConstants.formFieldType.DATE;
      case 20:
        return flowinglyConstants.formFieldType.DATETIME;
      case 21:
        return flowinglyConstants.formFieldType.SIGNATURE;
      case 23:
        return flowinglyConstants.formFieldType.LOOKUP;
      case 24:
        return flowinglyConstants.formFieldType.FORMULA;
      case 25:
        return flowinglyConstants.formFieldType.ATTACH_DOCUMENT;
      case 26:
        return flowinglyConstants.formFieldType.IMAGE;
      default:
        return numfield;
    }
  };

  const onEditFlowModelHandler = () => {
    tempModelerUrlService.openModeler(processMapId, false, false, null);
  };

  const handleSliderChange = (event: Event, newValue: number) => {
    setZoomValue(newValue);
  };

  const handleMouseScroll = (event: WheelEvent) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      setZoomValue((prevZoomValue) => {
        const scrollDirection = event.deltaY > 0 ? -1 : 1;
        const newZoomValue = Math.max(
          0,
          Math.min(200, prevZoomValue + scrollDirection)
        );
        return newZoomValue;
      });
    }
  };

  const cacheProcessMap = (map: IProcessMap) => {
    ProcessMapSchemaAndIdMapping[map.flowId] = map;
  };

  const cacheProcessMapComponent = (schemaId: string, map: IProcessMap) => {
    ProcessMapComponentSchemaAndSchemaIdMapping[schemaId] = map;
    cacheProcessMap(map);
  };

  return (
    <div>
      {diagramLoaded && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {!hideFlowModelOpenButton && (
            <Box>
              {isFlowModelEditable ? (
                <CustomButton
                  buttonText="Edit Flow Model"
                  onClick={onEditFlowModelHandler}
                />
              ) : (
                <CustomButton
                  buttonText="View Flow Model"
                  onClick={onEditFlowModelHandler}
                />
              )}
              <Button
                variant="contained"
                className="process-map-v2-share-button"
                onClick={onShareModelHandler}
              >
                <i className="fa-regular fa-share-nodes process-map-v2-share-icon"></i>
                Share
              </Button>
              <Dialog open={shareModalOpen} onClose={shareModalClose}>
                <ShareModal
                  closeModal={shareModalClose}
                  isPublicMap={processMap.isPublic}
                  currentProcessMapId={currentProcessMapId}
                />
              </Dialog>
            </Box>
          )}
        </Box>
      )}

      <Box id={currentProcessMapId} ref={divRef}></Box>

      {diagramLoaded && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            width={250}
            paddingLeft={2}
            paddingRight={2}
            className={
              'process-map-v2-view-left-panel-process-map-slider-container'
            }
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <i className="fas fa-minus"></i>
              <Slider
                className={'process-map-v2-view-left-panel-process-map-slider'}
                min={0}
                max={200}
                value={zoomValue}
                step={1}
                onChange={handleSliderChange}
              />
              <i className="fas fa-plus"></i>
              <span style={{ marginLeft: '5px', width: '60px' }}>
                {zoomValue}%
              </span>
            </Stack>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ProcessMap;
